import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DialogVariableEditModal from '@totem/components/devices/deviceDetails/deviceVariables/DialogVariableEditModal';
import IBDIcon from '@totem/styles/Icon';
import { VariableExtended } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';
import { getTablePagination } from '@totem/utilities/paginationUtilities';

const DeviceVariablesTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);
  const [variables, setVariables] = useState<VariableExtended[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [selectedVariable, setSelectedVariable] =
    useState<VariableExtended>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const handleShowEditModal = (selected: VariableExtended) => {
    setSelectedVariable(selected);
    setShowEdit(true);
  };

  useEffect(() => {
    const working: VariableExtended[] = [];

    if (
      isNotNull(deviceData) &&
      isNotNull(deviceData.device) &&
      isNotNull(deviceData.device.variableCollection)
    ) {
      for (
        let colIdx = 0;
        colIdx < deviceData.device.variableCollection.length;
        colIdx++
      ) {
        for (
          let varIdx = 0;
          varIdx <
          deviceData.device.variableCollection[colIdx].variables.length;
          varIdx++
        ) {
          working.push({
            source: deviceData.device.variableCollection[colIdx].source,
            configID: deviceData.device.variableCollection[colIdx].configID,
            deviceKey: deviceData.device.variableCollection[colIdx].deviceKey,
            ...deviceData.device.variableCollection[colIdx].variables[varIdx],
          });
        }
      }
    }
    setVariables(working);
  }, [deviceData]);

  const columns: ColumnProps<VariableExtended>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      sorter: (compA, compB) =>
        compA.label === compB.label ? 0 : compA.label > compB.label ? 1 : -1,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
      key: 'protocol',
      sorter: (compA, compB) =>
        compA.protocol === compB.protocol
          ? 0
          : compA.protocol > compB.protocol
            ? 1
            : -1,
    },
    {
      title: 'Display Label',
      dataIndex: 'ibdLabel',
      key: 'ibdLabel',
      sorter: (compA, compB) =>
        compA.ibdLabel === compB.ibdLabel
          ? 0
          : compA.ibdLabel > compB.ibdLabel
            ? 1
            : -1,
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
      sorter: (compA, compB) =>
        compA.purpose === compB.purpose
          ? 0
          : compA.purpose > compB.purpose
            ? 1
            : -1,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (_, variable: VariableExtended) => {
        if (!variable.isNumeric && variable.stringValue !== '') {
          return <span>{variable.stringValue}</span>;
        }
        return <span>{variable.value}</span>;
      },
      sorter: (compA, compB) =>
        compA.value.toString() === compB.value.toString()
          ? 0
          : compA.value.toString() > compB.value.toString()
            ? 1
            : -1,
    },
    {
      title: 'Min Value',
      dataIndex: 'minValue',
      key: 'minValue',
      sorter: (compA, compB) =>
        compA.minValue === compB.minValue
          ? 0
          : compA.minValue > compB.minValue
            ? 1
            : -1,
    },
    {
      title: 'Max Value',
      dataIndex: 'maxValue',
      key: 'maxValue',
      sorter: (compA, compB) =>
        compA.maxValue === compB.maxValue
          ? 0
          : compA.maxValue > compB.maxValue
            ? 1
            : -1,
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, variable: VariableExtended) => (
        <div className="center-table-cell">
          <span onClick={() => handleShowEditModal(variable)}>
            <IBDIcon name={'Edit'} />
          </span>
        </div>
      ),
    },
  ];

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;
    setOffset((current - 1) * limit);
    setLimit(pageSize);
  };

  const pagination = useMemo(() => {
    return getTablePagination({ limit, offset }, variables.length);
  }, [limit, offset, variables]);

  return (
    <>
      <Table
        showSorterTooltip
        columns={columns}
        pagination={pagination}
        onChange={handlePagination}
        dataSource={variables}
        loading={loading}
        rowKey={(record) => record.name + record.label}
      />
      {showEdit && (
        <DialogVariableEditModal
          visible={showEdit}
          onClose={() => {
            setShowEdit(false);
            setSelectedVariable(null);
          }}
          configId={
            isNotNull(selectedVariable) ? selectedVariable.configID : null
          }
          deviceId={isNotNull(deviceData) ? deviceData.device.id : null}
          variableId={isNotNull(selectedVariable) ? selectedVariable.id : null}
          ibdLabel={
            isNotNull(selectedVariable) ? selectedVariable.ibdLabel : null
          }
          purpose={
            isNotNull(selectedVariable) ? selectedVariable.purpose : null
          }
        />
      )}
    </>
  );
};

export default DeviceVariablesTable;
