import React, { ReactNode, useEffect, useState } from 'react';

import {
  User,
  UserAction,
  UserFilters,
  UserQueryResults,
  UsersConnectionInput,
} from '@totem/components/users/container/types';
import UserContext from '@totem/components/users/container/usersContainerContext';
import {
  addUserFilters,
  buildUserFilters,
} from '@totem/components/users/container/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  actions?: UserAction[];
  children?: ReactNode;
  defaultFilters?: UserFilters;
  staticFilters?: UserFilters;
};

const UsersEmbeddedContainer = ({
  refresh,
  onRecordTotalChanged,
  onDataRefreshRequested,
  actions,
  children,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [input, updateInput] = useState<UsersConnectionInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'email',
    sortDirection: '1',
    role:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.role !== 'undefined' &&
      defaultFilters.role !== null
        ? defaultFilters.role
        : [],
    searchEmail: '',
    searchFirstName: '',
    searchLastName: '',
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [userData, setUserData] = useState<UserQueryResults>(null);

  const setInput = (updated: Partial<UsersConnectionInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (!refreshData) {
      updateInput({ ...input, page: 1 });
      setRefreshData(true);
    }
  }, [staticFilters]);

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (
      !refreshData &&
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildUserFilters(input),
    };

    params.filters = addUserFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      setIsLoading(true);
      const params: Params = buildParameters();

      const endpoint = USERS_ENDPOINT;

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: UserQueryResults) => {
          setUserData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
    }
  }, [refreshData, input]);

  const handleAction = (action: string, user: User) => {
    let actionHandled = false;
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, user);
          }
        }
      }
    }
    if (!actionHandled) {
      console.log('Unhandled Action');
    }
  };

  return (
    <UserContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        userData,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </UserContext.Provider>
  );
};

export default UsersEmbeddedContainer;
