import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

import NotFound from '@totem/components/notFound/NotFound';
import colors from '@totem/styles/colors';
import { getBaseRoutes } from '@totem/utilities/routes';

import Header from './header/Header';
import ProfileUpdateModal from './settings/sections/profile/ProfileUpdateModal';
import LeftNav from './LeftNav';
import { RouteContextProvider } from './RouteContext';
import UserProfileContext from './UserProfileContext';

import './base.css';

const { Content } = Layout;

const styles = {
  content: {
    backgroundColor: colors.neutral.white,
    display: 'flex',
    flexDirection: 'column' as any,
  },
};

const Base = () => {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <LeftNav styleName="no-print" />
      <Layout>
        <Header />
        <Content style={styles.content}>
          <Routes>
            {getBaseRoutes(userProfile).map((route) => {
              const enabled =
                typeof route.render === 'function'
                  ? route.render()
                  : route.render ?? true;
              return (
                enabled && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RouteContextProvider value={route}>
                        <route.component />
                      </RouteContextProvider>
                    }
                  />
                )
              );
            })}
            <Route element={<NotFound />} />
          </Routes>
          <ProfileUpdateModal />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Base;
