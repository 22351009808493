import CrossOrgDevicesPage from '@totem/components/administration/devices/CrossOrgDeviceManagement/CrossOrgDeviceManagementPage';
import CrossOrgRemoteAccessGatewaysPage from '@totem/components/administration/devices/RemoteAccessGateways/CrossOrgRemoteAccessGateways';
import Mappings from '@totem/components/administration/mappings/Mappings';
import ZoneMappingPage from '@totem/components/administration/zoneMappings/ZoneMappingPage';
import AgreementsPage from '@totem/components/agreements/AgreementReport/AgreementsPage';
import AssetInventoryPage from '@totem/components/assetInventory/AssetInventoryPage';
import BuildingDetail from '@totem/components/buildingDetail/BuildingDetail';
import BuildingSummaryPage from '@totem/components/buildingSummary/BuildingSummaryPage';
import Contract from '@totem/components/contracts/details/Contract';
import Contracts from '@totem/components/contracts/list/Contracts';
import ControlSystemBackups from '@totem/components/controlSystemBackups/ControlSystemBackups';
import ControlSystemDetail from '@totem/components/controlSystemDetail/ControlSystemDetail';
import ControlSystemReportPage from '@totem/components/controlSystemReport/ControlSystemReportPage';
import ControlSystems from '@totem/components/controlSystems/ControlSystems';
import Customers from '@totem/components/customers/Customers';
import DeviceBackups from '@totem/components/deviceBackups/DeviceBackups';
import DeviceDetailsPage from '@totem/components/devices/deviceDetails/DeviceDetailsPage';
import DevicesPage from '@totem/components/devices/DevicesPage';
import DeviceSoftwareEndOfLifePage from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfLifePage';
import DeviceTypesPage from '@totem/components/deviceTypes/DeviceTypesPage';
import EndOfLifePage from '@totem/components/endoflife/EndOfLifePage';
import EnergyPage from '@totem/components/energy/EnergyPage';
import EventsPage from '@totem/components/events/EventsPage';
import EventGraphsPage from '@totem/components/events/graphs/EventGraphsPage';
import Findings from '@totem/components/findings/Findings';
import HealthRiskAcknowledgmentDetail from '@totem/components/healthRiskAcknowledgmentDetail/HealthRiskAcknowledgmentDetail';
import HealthRiskSurveyDetail from '@totem/components/healthRiskSurveyDetail/HealthRiskSurveyDetail';
import ComponentInstancePage from '@totem/components/home/ComponentInstancePage';
import ComponentPage from '@totem/components/home/ComponentPage';
import Home from '@totem/components/home/Home';
import PendingPage from '@totem/components/internalOnly/netSuitePending/PendingPage';
import NmapDetail from '@totem/components/nmapDetail/NmapDetail';
import Policies from '@totem/components/policies/Policies';
import PolicyAuditDetail from '@totem/components/policyAuditDetail/PolicyAuditDetail';
import PolicyAuditReport from '@totem/components/policyAuditDetail/Report';
import PolicyAuditFindingDetail from '@totem/components/policyAuditFindingDetail/PolicyAuditFindingDetail';
import PolicyAudits from '@totem/components/policyAudits/PolicyAudits';
import Portfolio from '@totem/components/portfolio/Portfolio';
import Regions from '@totem/components/regions/Regions';
import RemoteAccessRolePage from '@totem/components/remoteAccess/roleUsers/byRole/RemoteAccessRolePage';
import RemoteAccessUserPage from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessUserPage';
import RemoteAccessUserDetails from '@totem/components/remoteAccess/userDetails/RemoteAccessUser';
import Settings from '@totem/components/settings/Settings';
import CvePage from '@totem/components/software/cves/cvePage';
import PackagePage from '@totem/components/software/software_package/packagePage';
import SoftwarePage from '@totem/components/software/software_report/softwarePage';
import VulnerabilityReportPage from '@totem/components/software/vulnerabilityReport/vulnerabilityReportPage';
import StylesTest from '@totem/components/styles/Styles';
import AnalysisPage from '@totem/components/surveyV2/analysis/AnalysisPage';
import AnalysisReportPage from '@totem/components/surveyV2/analysisReport/AnalysisReportPage';
import AuditReportPage from '@totem/components/surveyV2/auditReport/AuditReportPage';
import FindingPage from '@totem/components/surveyV2/findings/FindingPage';
import FindingReportPage from '@totem/components/surveyV2/findingsReport/FindingReportPage';
import ScoringPage from '@totem/components/surveyV2/instanceDetail/score/ScoringPage';
import SurveyDetailPage from '@totem/components/surveyV2/instanceDetail/SurveyDetailPage';
import SurveyInstancesPage from '@totem/components/surveyV2/instances/SurveyInstancesPage';
import PoliciesPage from '@totem/components/surveyV2/policies/PoliciesPage';
import PolicyAuditReportPage from '@totem/components/surveyV2/policyAuditReport/PolicyAuditReportPage';
import SurveyTemplatePage from '@totem/components/surveyV2/templateDetails/SurveyTemplatePage';
import TemplatesPage from '@totem/components/surveyV2/templates/TemplatesPage';
import WorkSummaryPage from '@totem/components/surveyV2/work/WorkSummaryPage';
import AgingReportPage from '@totem/components/ticketing/agingReport/AgingReportPage';
import TicketDetails from '@totem/components/ticketing/details/ticket';
import Tickets from '@totem/components/ticketing/list/tickets';
import TicketsPage from '@totem/components/ticketing/list/TicketsPage';
import QueueTotalsPage from '@totem/components/ticketing/queueTotals/QueueTotalsPage';
import UserProfilePage from '@totem/components/userProfile/UserProfile';
import UserManagement from '@totem/components/users/UserManagement';
import Users from '@totem/components/users/Users';
import VendorsPage from '@totem/components/vendors/VendorPage';
import { Route } from '@totem/types/route';
import { UserProfile } from '@totem/types/user';
import configuration from '@totem/utilities/configuration';

const baseRoutes: Route[] = [
  {
    path: '',
    pageTitle: 'Portfolio',
    component: Portfolio, //WorkSummary, Changed Homepage 10/24/2023
    exact: true,
    breadcrumbs: [],
  },
  // Home
  {
    path: 'home',
    pageTitle: 'Home',
    component: Home,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'component/:id',
    pageTitle: '',
    component: ComponentPage,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'componentInstance/:page/:pageNumber/:componentInstanceId',
    pageTitle: '',
    component: ComponentInstancePage,
    exact: true,
    breadcrumbs: [],
  },
  //
  {
    path: 'stylesTest',
    pageTitle: 'Styles Test',
    component: StylesTest,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'customers',
    pageTitle: 'Customers',
    component: Customers,
    exact: true,
    breadcrumbs: [{ label: 'Customers', link: '/customers' }],
    render: !configuration.isProd && !configuration.isProdNew,
  },
  // Portfolio
  {
    path: 'portfolio',
    pageTitle: 'Portfolio',
    component: Portfolio,
    exact: true,
    breadcrumbs: [{ label: 'Portfolio', link: '/portfolio' }],
  },
  {
    path: 'regions',
    pageTitle: 'Regions',
    component: Regions,
    exact: true,
    breadcrumbs: [{ label: 'Regions', link: '/regions' }],
  },
  // {
  //   path: 'buildings',
  //   pageTitle: 'Buildings',
  //   component: Buildings,
  //   exact: true,
  //   breadcrumbs: [{ label: 'Buildings', link: '/buildings' }],
  // },
  {
    path: 'buildings',
    pageTitle: 'Buildings',
    component: BuildingSummaryPage,
    exact: true,
    breadcrumbs: [{ label: 'Buildings', link: '/buildings' }],
  },
  {
    path: 'controlsystems',
    pageTitle: 'Control Systems',
    component: ControlSystems,
    exact: true,
    breadcrumbs: [{ label: 'Control Systems', link: '/controlsystems' }],
  },
  //
  {
    path: 'backups',
    pageTitle: 'Backup Summary',
    component: ControlSystemBackups,
    exact: true,
    breadcrumbs: [
      { label: 'Control Systems', link: '/controlsystems' },
      { label: 'Backups', link: '/backups' },
    ],
  },
  {
    path: 'deviceBackups',
    pageTitle: 'Backup Summary',
    component: DeviceBackups,
    exact: true,
    breadcrumbs: [
      { label: 'Devices', link: '/devices' },
      { label: 'Backups', link: '/backups' },
    ],
  },
  {
    path: 'buildings/:id',
    pageTitle: 'Building Detail',
    component: BuildingDetail,
    exact: true,
    breadcrumbs: [
      { label: 'Buildings', link: '/buildings' },
      { label: 'Building Overview' },
    ],
  },
  {
    path: 'surveyinstances/:id/score',
    pageTitle: 'Survey Results',
    component: ScoringPage,
    exact: true,
    breadcrumbs: [{ label: 'Surveys', link: `/surveyinstances` }],
  },
  // Surveys V2
  {
    path: 'surveytemplates',
    pageTitle: 'Survey Templates',
    component: TemplatesPage,
    exact: true,
    breadcrumbs: [{ label: 'Survey Templates' }],
  },
  {
    path: 'surveytemplates/:id',
    pageTitle: 'Survey Template Detail',
    component: SurveyTemplatePage,
    exact: true,
    breadcrumbs: [{ label: 'Survey Templates', link: `/surveytemplates` }],
  },
  {
    path: 'surveyinstances',
    pageTitle: 'Survey Management',
    component: SurveyInstancesPage,
    exact: true,
    breadcrumbs: [{ label: 'Surveys' }],
  },
  {
    path: 'surveyinstances/:id',
    pageTitle: 'Survey Detail',
    component: SurveyDetailPage,
    exact: true,
    breadcrumbs: [{ label: 'Surveys', link: `/surveyinstances` }],
  },
  {
    path: 'surveyinstances/:id/report',
    pageTitle: 'Audit Report',
    component: AuditReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Surveys', link: '/surveyinstances' }],
  },
  {
    path: 'policies/v2',
    pageTitle: 'Policies',
    component: PoliciesPage,
    exact: true,
    breadcrumbs: [{ label: 'Policies' }],
  },
  // Policy Audits - Original
  {
    path: 'policyaudits/v2',
    pageTitle: 'Policy Audit Management',
    component: PolicyAuditReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Audits', link: '/policyaudits/v2' }],
  },
  {
    path: 'policyaudits',
    pageTitle: 'Audit Management',
    component: PolicyAudits,
    exact: true,
    breadcrumbs: [{ label: 'Audits', link: '/policyaudits' }],
  },
  {
    path: 'policyaudits/:id',
    pageTitle: 'Policy Audit Detail',
    component: PolicyAuditDetail,
    exact: true,
    breadcrumbs: [{ label: 'Audits', link: '/policyaudits/v2' }],
  },
  {
    path: 'policyaudits/:id/report',
    pageTitle: 'Audit Report',
    component: PolicyAuditReport,
    exact: true,
    breadcrumbs: [{ label: 'Audits', link: '/policyaudits' }],
  },
  {
    path: 'findings',
    pageTitle: 'Findings',
    component: Findings,
    exact: true,
    breadcrumbs: [{ label: 'Findings' }],
  },
  // {
  //   path: 'findings/policyaudits',
  //   pageTitle: 'Audit Analysis',
  //   component: PolicyAuditFindings,
  //   exact: true,
  //   breadcrumbs: [{ label: 'Audit Analysis' }],
  // },
  {
    path: 'findings/policyaudits',
    pageTitle: 'Audit Analysis',
    component: AnalysisReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Audit Analysis' }],
  },
  {
    path: 'findings/policyaudits/:subjectId',
    pageTitle: 'Audit Analysis',
    component: PolicyAuditFindingDetail,
    exact: true,
    breadcrumbs: [
      { label: 'Audit Analysis', link: `/findings/policyaudits` },
      { label: 'Audit Analysis Details' },
    ],
  },
  {
    path: 'policies',
    pageTitle: 'Policies',
    component: Policies,
    exact: true,
    breadcrumbs: [{ label: 'Policies' }],
  },
  // Policy Audits V2
  {
    path: 'findings/policyaudits/v2',
    pageTitle: 'Audit Analysis',
    component: AnalysisPage,
    exact: true,
    breadcrumbs: [{ label: 'Audit Analysis' }],
  },
  {
    path: 'findings/policyaudits/v2/:questionId',
    pageTitle: 'Audit Analysis',
    component: FindingPage,
    exact: true,
    breadcrumbs: [
      { label: 'Audit Analysis', link: `/findings/policyaudits` },
      { label: 'Audit Analysis Details' },
    ],
  },
  {
    path: 'findings/policyaudits/v3/:question',
    pageTitle: 'Audit Analysis',
    component: FindingReportPage,
    exact: true,
    breadcrumbs: [
      { label: 'Audit Analysis', link: `/findings/policyaudits` },
      { label: 'Audit Analysis Details' },
    ],
  },
  //
  {
    path: 'controlsystems/:id',
    pageTitle: 'System Detail',
    component: ControlSystemDetail,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'nmap/:id',
    pageTitle: 'Nmap Details',
    component: NmapDetail,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'healthrisksurveys/:id',
    pageTitle: 'Health Risk Survey',
    component: HealthRiskSurveyDetail,
    exact: true,
    breadcrumbs: [{ label: 'Health Risk' }],
  },
  {
    path: 'healthriskpolicyacknowledgments/:id',
    pageTitle: 'Health Risk Acknowledgment',
    component: HealthRiskAcknowledgmentDetail,
    exact: true,
    breadcrumbs: [{ label: 'Acknowledgment' }],
  },
  {
    path: 'users',
    pageTitle: 'Users',
    component: Users,
    exact: true,
    breadcrumbs: [{ label: 'Users' }],
  },
  {
    path: 'userProfile',
    pageTitle: 'User Profile',
    component: UserProfilePage,
    exact: true,
    breadcrumbs: [{ label: 'User Profile' }],
  },
  {
    path: 'usermanagement',
    pageTitle: 'Users',
    component: UserManagement,
    exact: true,
    breadcrumbs: [{ label: 'Users' }],
  },
  {
    path: 'settings',
    pageTitle: 'Account Settings',
    component: Settings,
    exact: true,
    breadcrumbs: [{ label: 'Account Settings' }],
  },
  {
    path: 'remoteAccessUsers',
    pageTitle: 'Remote Access',
    component: RemoteAccessUserPage,
    exact: true,
    breadcrumbs: [{ label: 'Remote Access Users' }],
  },
  {
    path: 'remoteAccessUsers/role/:id',
    pageTitle: 'Remote Access Role',
    component: RemoteAccessRolePage,
    exact: true,
    breadcrumbs: [{ label: 'Remote Access Role' }],
  },
  {
    path: 'remoteAccessUsers/user/:id',
    pageTitle: 'Remote Access User',
    component: RemoteAccessUserDetails,
    exact: true,
    breadcrumbs: [{ label: 'Remote Access User' }],
  },
  {
    path: 'events',
    pageTitle: 'Events',
    component: EventsPage,
    exact: true,
    breadcrumbs: [{ label: 'Events' }],
  },
  {
    path: 'eventgraphs',
    pageTitle: 'Event Graphs',
    component: EventGraphsPage,
    exact: true,
    breadcrumbs: [{ label: 'Event Graphs' }],
  },
  {
    path: 'devices',
    pageTitle: 'Devices',
    component: DevicesPage,
    exact: true,
    breadcrumbs: [{ label: 'Devices' }],
  },
  {
    path: 'devices/:id',
    pageTitle: 'Device Detail',
    component: DeviceDetailsPage,
    exact: true,
    breadcrumbs: [
      { label: 'Devices', link: '/devices' },
      { label: 'Device Overview' },
    ],
  },
  {
    path: 'devices/software/endOfSupport',
    pageTitle: 'Installed Software End-of-Support (Next 12 Months)',
    component: DeviceSoftwareEndOfLifePage,
    exact: true,
    breadcrumbs: [
      { label: 'Portfolio Overview', link: '/' },
      { label: 'Installed Software End-of-Support' },
    ],
  },
  {
    path: 'ticketing',
    pageTitle: 'Tickets',
    component: TicketsPage,
    exact: true,
    breadcrumbs: [{ label: 'Tickets' }],
  },
  {
    path: 'ticketingQueues',
    pageTitle: 'Ticket Queues',
    component: QueueTotalsPage,
    exact: true,
    breadcrumbs: [{ label: 'Ticket Queues' }],
  },
  {
    path: 'ticket/:id',
    pageTitle: 'Tickets',
    component: TicketDetails,
    exact: true,
    breadcrumbs: [{ label: 'Ticket' }],
  },
  {
    path: 'ticketAging',
    pageTitle: 'Ticket Aging',
    component: AgingReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Ticket Aging' }],
  },
  {
    path: 'contracts',
    pageTitle: 'Contracts',
    component: Contracts,
    exact: true,
    breadcrumbs: [{ label: 'Contracts' }],
  },
  {
    path: 'contract/:id',
    pageTitle: 'Contract',
    component: Contract,
    exact: true,
    breadcrumbs: [{ label: 'Contract' }],
  },
  {
    path: 'controlSystemReport',
    pageTitle: 'Control Systems',
    component: ControlSystemReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Control System Report' }],
  },
  {
    path: 'agreements',
    pageTitle: 'Agreements',
    component: AgreementsPage,
    exact: true,
    breadcrumbs: [{ label: 'Agreements' }],
  },
  {
    path: 'adminDevices',
    pageTitle: 'All Devices',
    component: CrossOrgDevicesPage,
    exact: true,
    breadcrumbs: [{ label: 'All Devices' }],
  },
  {
    path: 'zoneMappings',
    pageTitle: 'Zone Mappings',
    component: ZoneMappingPage,
    exact: true,
    breadcrumbs: [{ label: 'Zone Mappings' }],
  },
  {
    path: 'adminGateways',
    pageTitle: 'All Gateways',
    component: CrossOrgRemoteAccessGatewaysPage,
    exact: true,
    breadcrumbs: [{ label: 'All Gateways' }],
  },
  {
    path: 'pendingImports',
    pageTitle: 'NetSuite Pending Imports',
    component: PendingPage,
    exact: true,
    breadcrumbs: [{ label: 'Pending Imports' }],
  },
  {
    path: 'endoflife',
    pageTitle: 'Product End of Life',
    component: EndOfLifePage,
    exact: true,
    breadcrumbs: [{ label: 'Product End of Life' }],
  },
  {
    path: 'assetInventory',
    pageTitle: 'Asset Inventory',
    component: AssetInventoryPage,
    exact: true,
    breadcrumbs: [{ label: 'Asset Inventory' }],
  },
  {
    path: 'energy',
    pageTitle: 'Energy Overview',
    component: EnergyPage,
    exact: true,
    breadcrumbs: [{ label: 'Energy Overview' }],
  },
  {
    path: 'software',
    pageTitle: 'Software Catalog',
    component: SoftwarePage,
    exact: true,
    breadcrumbs: [{ label: 'Software Catalog' }],
  },
  {
    path: 'software/:id',
    pageTitle: 'Software Package',
    component: PackagePage,
    exact: true,
    breadcrumbs: [{ label: 'Software Package' }],
  },
  {
    path: 'software/cve/:id',
    pageTitle: 'CVE',
    component: CvePage,
    exact: true,
    breadcrumbs: [{ label: 'CVE' }],
  },
  {
    path: 'software/vulnerabilityReport',
    pageTitle: 'Software Vulnerability Report',
    component: VulnerabilityReportPage,
    exact: true,
    breadcrumbs: [{ label: 'Software Vulnerability Report' }],
  },
  {
    path: 'vendors',
    pageTitle: 'Vendors',
    component: VendorsPage,
    exact: true,
    breadcrumbs: [{ label: 'Vendors' }],
  },
  {
    path: 'deviceTypes',
    pageTitle: 'DeviceTypes',
    component: DeviceTypesPage,
    exact: true,
    breadcrumbs: [{ label: 'Device Types' }],
  },
  {
    path: 'admin/mappings',
    pageTitle: 'Mappings',
    component: Mappings,
    exact: true,
    breadcrumbs: [{ label: 'Mappings' }],
  },
];

export const getBaseRoutes = (user: UserProfile) =>
  baseRoutes.map((route) => ({
    ...route,
    /**
     * all base routes prefixed with /dashboard and organization breadcrumb
     * all breadcrumb links prefixed with /dashboard
     */
    path: route.path,
    breadcrumbs: [
      {
        link: '/dashboard',
        label: user.organization?.name || 'IB',
      },
      ...route.breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        link: breadcrumb.link ? `/dashboard${breadcrumb.link}` : '',
      })),
    ],
  }));

const vendorRoutes: Route[] = [
  {
    path: '',
    pageTitle: 'Your Work',
    component: WorkSummaryPage, // WorkSummary
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'surveyinstances/:id',
    pageTitle: 'Survey Detail',
    component: SurveyDetailPage,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'surveyinstances/:id/score',
    pageTitle: 'Survey Results',
    component: ScoringPage,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'policyaudits/:id',
    pageTitle: 'Policy Audit Detail',
    component: PolicyAuditDetail,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'policyaudits/:id/report',
    pageTitle: 'Audit Report',
    component: PolicyAuditReport,
    exact: true,
    breadcrumbs: [],
  },
  {
    path: 'healthrisksurveys/:id',
    pageTitle: 'Health Risk Survey',
    component: HealthRiskSurveyDetail,
    exact: true,
    breadcrumbs: [{ label: 'Health Risk' }],
  },
  {
    path: 'healthriskpolicyacknowledgments/:id',
    pageTitle: 'Health Risk Acknowledgment',
    component: HealthRiskAcknowledgmentDetail,
    exact: true,
    breadcrumbs: [{ label: 'Acknowledgment' }],
  },
  {
    path: 'userProfile',
    pageTitle: 'User Profile',
    component: UserProfilePage,
    exact: true,
    breadcrumbs: [{ label: 'User Profile' }],
  },
  {
    path: 'settings',
    pageTitle: 'Settings',
    component: Settings,
    exact: true,
    breadcrumbs: [{ label: 'Settings' }],
  },
  {
    path: 'ticketing',
    pageTitle: 'Tickets',
    component: Tickets,
    exact: true,
    breadcrumbs: [{ label: 'Tickets' }],
  },
];

export const getVendorRoutes = (user: UserProfile) =>
  vendorRoutes.map((route) => ({
    ...route,
    /**
     * all base routes prefixed with /vendor-dashboard and organization breadcrumb
     * all breadcrumb links prefixed with /vendor-dashboard
     */
    path: route.path,
    breadcrumbs: [
      {
        link: '/vendor-dashboard',
        label: user.organization?.name || 'IB',
      },
      ...route.breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        link: breadcrumb.link ? `/vendor-dashboard${breadcrumb.link}` : '',
      })),
    ],
  }));
