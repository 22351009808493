import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { requestVersions } from '@totem/actions/versionActions';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import Notification from '@totem/components/common/Notification';
import OrganizationContainer from '@totem/components/common/organizationContext/OrganizationContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import { GET_USER } from '@totem/graph/user';
import { User, UserProfile } from '@totem/types/user';
import {
  getAccountPayloadSync,
  getToken,
} from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import authUtilities from '@totem/utilities/authUtilities';

type Props = {
  exact?: boolean;
  path?: string;
  component: any;
  roleSet: string[];
};

const styles = {
  spinnerContainer: {
    height: '100%',
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AuthorizedRoute = ({ component: Component, roleSet }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userProfile, setUserProfile] = useState<UserProfile>(null);

  const retrieveUserId = () => {
    if (!getToken()) {
      return 'LOGIN';
    }

    // TODO: Remove @ts-ignore : Token
    // @ts-ignore
    return getAccountPayloadSync().id;
  };

  const onCompleted = (user: UserProfile) => {
    const token = getAccountPayloadSync();
    // TODO: Remove @ts-ignore : Token
    // @ts-ignore
    const { role } = token;

    authUtilities.setUserRole(role);
    authUtilities.setUserRoleLevel(role);
    authUtilities.registerFullStory(user);
    api.setAuthToken();

    setUserProfile(
      // sort user organizations by name
      R.over(R.lensPath(['organizations']), R.sortBy(R.prop('name')))(user),
    );

    const organizations = !user.organizations
      ? []
      : user.organizations.filter((org) => org.isActive);

    if (!user.isEmailVerified) {
      navigate('/unverified-email');
    } else if (R.isEmpty(organizations)) {
      console.log(JSON.stringify(user));
      navigate('/login');
      //navigate('/no-access');
    } else if (!user.id) {
      navigate('/login');
    } else if (!user.organization) {
      navigate('/switch-organization');
    } else if (!authUtilities.minimumRequiredRole(roleSet)) {
      const endpoint = authUtilities.isVendorUser()
        ? '/vendor-dashboard'
        : '/dashboard';

      navigate(endpoint);
    }
  };

  const userId = retrieveUserId();

  const { loading, refetch, error } = useQuery<{ user: User }>(GET_USER, {
    variables: {
      id: userId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res?.user) {
        onCompleted(res.user);
      }
    },
  });

  useEffect(() => {
    if (userProfile?.id && !authUtilities.minimumRequiredRole(roleSet)) {
      const endpoint = authUtilities.isVendorUser()
        ? '/vendor-dashboard'
        : '/dashboard';

      navigate(endpoint);
    }
  }, []);

  useEffect(() => {
    dispatch(requestVersions());
  }, [dispatch]);

  useEffect(() => {
    if (typeof error !== 'undefined') {
      if (error) {
        navigate('/login');
      }
    }
  }, [error]);

  if (userId === 'LOGIN') {
    return <Navigate to="/login" replace />;
  }

  if (!userProfile?.id) {
    return (
      <div style={styles.spinnerContainer}>
        <DetailSpinner />
      </div>
    );
  }

  return (
    <UserProfileContext.Provider value={{ loading, refetch, userProfile }}>
      <OrganizationContainer>
        <div style={{ height: '100%' }}>
          <Notification />
          <Component />
        </div>
      </OrganizationContainer>
    </UserProfileContext.Provider>
  );
};

export default AuthorizedRoute;
