import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';

import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { DeviceVariableUpdate } from '@totem/components/devices/deviceDetails/deviceVariables/types';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

const FormItem = Form.Item;
const { Option } = Select;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  visible: boolean;
  onClose: () => void;
  configId: string;
  deviceId: string;
  variableId: string;
  ibdLabel: string;
  purpose: string;
};

const DialogVariableEditModal = ({
  visible,
  onClose,
  configId,
  deviceId,
  variableId,
  ibdLabel,
  purpose,
}: Props) => {
  const { onAction } = useContext(DeviceContext);
  const [selectedLabel, setSelectedLabel] = useState<string>(ibdLabel);
  const [selectedPurpose, setSelectedPurpose] = useState<string>(purpose);
  const [purposes, setPurposes] = useState<string[]>([]);
  const [isSendingUpdate, setIsSendingUpdate] = useState(false);

  useEffect(() => {
    fetch(`${DEVICES_ENDPOINT}/variable/purposes`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: string[]) => {
        setPurposes(result);
      });
  }, []);

  const sendDeviceVaroableUpdate = useCallback(
    async (update: DeviceVariableUpdate) => {
      if (isSendingUpdate) {
        return;
      }

      setIsSendingUpdate(true);

      fetch(`${DEVICES_ENDPOINT}/variable`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(update),
      }).then(() => {
        onClose();
        setIsSendingUpdate(false);
        onAction('refresh');
      });
    },
    [isSendingUpdate],
  );
  const handleSubmit = () => {
    const update: DeviceVariableUpdate = {
      configId,
      deviceId,
      variableId,
      ibdLabel: selectedLabel,
      purpose: selectedPurpose,
    };

    sendDeviceVaroableUpdate(update);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Activity</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Display Label" colon={false} style={styles.formItem}>
          <Input
            placeholder="Display Label"
            value={selectedLabel}
            onChange={(evt) => setSelectedLabel(evt.target.value)}
          />
        </FormItem>
        <FormItem label="Purpose" colon={false} style={styles.formItem}>
          <Select
            defaultValue={selectedPurpose}
            onChange={(value: string) => setSelectedPurpose(value)}
          >
            <Option value="">Other</Option>
            {isNotNull(purposes) &&
              purposes.map((chk) => (
                <Option key={chk} value={chk}>
                  {chk}
                </Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default DialogVariableEditModal;
