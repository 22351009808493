import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';

import {
  AutoCompleteResponse,
  AutoCompleteValue,
} from '@totem/types/autoComplete';
import { Filter } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { debounce } from '@totem/utilities/debounce';
import { AUTO_COMPLETE_ENDPOINT } from '@totem/utilities/endpoints';

const { Search } = Input;

const DEBOUNCE_TIME = 500;

const styles = {
  input: {
    width: '100%',
  },
};

export interface Props {
  label: string;
  type: string;
  limit: number;
  filters?: Filter[];
  selectedValue: AutoCompleteValue;
  style?: React.CSSProperties;
  onSelect: (selectedValue: AutoCompleteValue) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  allowClear?: boolean;
  allowFreeform?: boolean;
}

const GenericAutoComplete = ({
  type,
  label,
  limit,
  filters,
  selectedValue,
  style,
  onChange,
  onSelect,
  disabled,
  allowClear,
  allowFreeform,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [options, setOptions] = useState<AutoCompleteValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  //const [hadUserInteraction, setHadUserInteraction] = useState<boolean>(false);
  const handleSearch = useCallback(debounce(setSearchTerm, DEBOUNCE_TIME), []);

  const forceSelection =
    typeof allowFreeform !== 'undefined' && allowFreeform !== null
      ? !allowFreeform
      : true;

  const buildRequest = () => {
    return {
      type,
      searchTerms: searchTerm,
      limit,
      filters,
    };
  };

  useEffect(() => {
    setSearchTerm(isNotNull(selectedValue) ? selectedValue.display : '');
  }, [selectedValue]);

  useEffect(() => {
    if (searchTerm !== '') {
      setLoading(true);
      fetch(`${AUTO_COMPLETE_ENDPOINT}/`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(buildRequest()),
      })
        .then((res) => res.json())
        .then((result: AutoCompleteResponse) => {
          const deDupped: AutoCompleteValue[] = [];
          for (let idx = 0; idx < result.values.length; idx++) {
            if (
              deDupped.findIndex(
                (chk) => chk.value === result.values[idx].value,
              ) < 0
            ) {
              deDupped.push(result.values[idx]);
            }
          }
          setOptions(deDupped);
        })
        .then(() => setLoading(false));
    }
  }, [searchTerm, filters]);

  const getData = () =>
    options.map((option) => ({
      label: option.display,
      value: option.value,
    }));

  const handleSelect = (_, option: any) => {
    console.log(`handleSelect: ${option.value}`);
    onSelect(options.find((values) => values.value === option.value));
  };

  const onChanged = (val: string) => {
    console.log(`onChanged: ${val}`);
    setSearchTerm(val);
    onChange(val);
  };

  const onBlur = () => {
    if (forceSelection && searchTerm !== selectedValue.display) {
      setSearchTerm('');
    }
  };

  return (
    <AutoComplete
      value={searchTerm}
      onChange={onChanged}
      onBlur={onBlur}
      options={getData()}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={label}
      disabled={disabled ?? false}
      notFoundContent={'No results found'}
      children={
        <Search allowClear={allowClear} type="search" loading={loading} />
      }
    />
  );
};

export default GenericAutoComplete;
